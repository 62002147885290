/* eslint-disable import/no-named-as-default */
import { configureStore, isRejected } from "@reduxjs/toolkit";
import { api } from "config/apiHandler";
import cookiesManipulator from "services/browserStorage/cookies";
import authSlice from "store/slices/authSlice";

export const rtkQueryErrorLogger = () => (next) => async (action) => {
  // isRejectedWithValue Or isRejected
  if (isRejected(action)) {
    if (
      action.error?.message === "Authentication failed." ||
      action.error?.message === "Authorisation failed."
    ) {
      await cookiesManipulator.removeAuth();
    }
  }

  return next(action);
};

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auths: authSlice,
  },
  middleware: (gDM) => gDM().concat(api.middleware).concat(rtkQueryErrorLogger),
});

export default store;
