import { api } from "config/apiHandler";
import cookies from "services/browserStorage/cookies";

const auths = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (credentials) => ({
        url: "/auths/login",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (baseQueryReturnValue, meta, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          console.log(baseQueryReturnValue.data);
          cookies.setAuth(baseQueryReturnValue.data);
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => baseQueryReturnValue,
    }),
    logout: build.mutation({
      query: () => ({
        url: "/auths/logout",
        method: "PUT",
      }),
      transformResponse: async (baseQueryReturnValue, meta, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          await cookies.removeAuth();
          return baseQueryReturnValue.code;
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLoginMutation, useLogoutMutation } = auths;
