import React from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "store/services/authsApi";
import AuthsView from "views/Auths";

function Auths() {
  const [login, { isLoading, isError, isSuccess, reset, error }] = useLoginMutation();
  const navigate = useNavigate();
  const handleLogin = async (payload) => {
    try {
      await login(payload).unwrap();
      navigate("/projects");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <AuthsView
      handleLogin={handleLogin}
      isLoading={isLoading}
      isError={isError}
      reset={reset}
      error={error}
    />
  );
}

export default Auths;
