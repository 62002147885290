/** 
  All of the routes for the kai App are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `component` key is used to store the component of its route.
  11 The 'subRoute' key is used to add the fragment routes.
*/

//  layouts
import { Loader } from "components";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import PropTypes from "prop-types";
import { lazy, Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import cookiesManipulator from "services/browserStorage/cookies";

const Projects = lazy(() => import("containers/Projects"));
const Project = lazy(() => import("containers/Projects/Project"));
const ContentTypes = lazy(() => import("containers/Projects/ContentTypes"));
const Builder = lazy(() => import("containers/Builder"));
const DataCollection = lazy(() => import("containers/Builder/Data/DataCollection"));

function LazyProjects(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Projects {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyProject(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Project {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyContentTypes(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <ContentTypes {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyBuilder(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Builder {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyDataCollection(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <DataCollection {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}

function generateKey(layout, route, fragmentIdentifier) {
  return `/${layout}/${route}${fragmentIdentifier ? `#${fragmentIdentifier}` : ""}`;
}

const routes = [
  {
    type: "collapse",
    icon: "",
    name: "Projects",
    key: generateKey("app", "projects"),
    route: "/projects",
    layout: "",
    component: <LazyProjects />,
  },
  {
    type: "collapse",
    icon: "",
    name: "project",
    key: generateKey("app", "project"),
    route: "/project",
    layout: "",
    component: <LazyProject />,
  },
  {
    type: "collapse",
    icon: "",
    name: "content-types",
    key: generateKey("app", "projects"),
    route: "/project/:projectId/:projectCode/content-types",
    layout: "",
    component: <LazyContentTypes />,
  },

  {
    type: "collapse",
    icon: "",
    name: "builder",
    key: generateKey("app", "projects", "builder"),
    route: "/project/:projectId/:projectCode/builder/:step",
    layout: "",
    component: <LazyBuilder />,
  },
  {
    type: "collapse",
    icon: "",
    name: "builder",
    key: generateKey("app", "projects", "builder"),
    route: "/project/:projectId/:projectCode/builder/data/:modelTypeId/:modelTypeCode",
    layout: "",
    component: <LazyBuilder />,
  },
  {
    type: "collapse",
    icon: "",
    name: "data-collection",
    key: generateKey("app", "projects", "data-collection"),
    route: "/project/:projectId/:projectCode/builder/data/collection/:modelTypeId/:modelTypeCode",
    layout: "",
    component: <LazyDataCollection />,
  },
];

export function RequireAuth({ children }) {
  const authed = !!cookiesManipulator.getAuth().token;
  const location = useLocation();
  return authed === true ? (
    children
  ) : (
    <Navigate to="/auths/login" replace state={{ path: location.pathname }} />
  );
}
RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default routes;
