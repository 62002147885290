import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { ReactComponent as KaiFormLogo } from "assets/images/KaiFormLogo.svg";
import { Avatar, Button, Typography } from "components";
//  components
import Box from "components/Box";
//  context
import { setMiniSidenav, setTransparentNavbar, useUIController } from "context";
import MENUS from "data/constants/MENUS";
import { Menu } from "modules";
// modules
// Custom styles for DashboardNavbar
import {
  avatarStyle,
  hideAtBelowMD,
  navbar,
  navbarContainer,
  navbarMobileMenu,
  navbarRow,
} from "modules/Navbars/DashboardNavbar/styles";
import Notification from "modules/Notification";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function DashboardNavbar({
  absolute = false,
  light = false,
  isMini = false,
  handleLogout,
  userData,
}) {
  const [navbarType, setNavbarType] = useState();
  const [notificationAnchor, setNotificationAnchor] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [controller, dispatch] = useUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;

  const navigate = useNavigate();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Render the notifications menu

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  // For Menu Item List

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectingMenuItems = async (item) => {
    if (item.code === "logout") {
      await handleLogout();
    }
    if (item.code === "settings") {
      navigate("/app/settings/profile");
    }
    setAnchorEl(null);
  };

  // here the logic of notification menu

  const isNotificationOpen = Boolean(notificationAnchor);

  const handleNotificationClick = (event) => {
    setNotificationAnchor(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchor(null);
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {isMini ? null : (
          <Box sx={(theme) => navbarRow(theme, { isMini })}>
            <Box component={Link} to="/" py={0} lineHeight={0} pl={{ xs: 0, lg: 0 }}>
              {/* <KaiFormLogo /> */}
              Platform
            </Box>
            <Box className="flex-container" gap="1rem">
              {/* <Box color={light ? "white" : "inherit"} display="inline-block">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
              </Box> */}

              <Box>
                <IconButton onClick={handleNotificationClick}>
                  <NotificationsNoneOutlinedIcon />
                </IconButton>
                <Notification
                  open={isNotificationOpen}
                  anchorEl={notificationAnchor}
                  handleClose={handleNotificationClose}
                />
              </Box>
              <Box>
                <IconButton sx={{ p: 0 }} onClick={handleClick}>
                  <Avatar
                    src={userData.status === "fulfilled" ? userData.data.user.photo_url : ""}
                    sx={(theme) =>
                      avatarStyle(theme, { hasImage: !!userData?.data?.user?.photo_url })
                    }
                  />
                </IconButton>
              </Box>
            </Box>
            <Menu
              menuList={MENUS.profile}
              onSelectItem={handleSelectingMenuItems}
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
            />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  handleLogout: PropTypes.func.isRequired,
  userData: PropTypes.instanceOf(Object).isRequired,
};

export default DashboardNavbar;
