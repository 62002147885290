const MENUS = Object.freeze({
  profile: [
    { value: 1, label: "Settings", code: "settings" },
    { value: 2, label: "Sign Out", code: "logout" },
  ],
  projectCard: [
    { value: 1, label: "Delete", code: "delete" },
    { value: 1, label: "Edit", code: "edit" },
  ],
  customOptions: [
    { value: 1, label: "Delete", code: "delete" },
    { value: 1, label: "Edit", code: "edit" },
  ],
  sortBy: [
    { value: 1, label: "Name A to Z", code: "sort_name_asc" },
    { value: 1, label: "Name Z to A", code: "sort_name_dsc" },
    { value: 1, label: "Date: Oldest to latest", code: "date_old_new" },
    { value: 1, label: "Date: latest to oldest", code: "date_new_old" },
  ],
});

export default MENUS;
