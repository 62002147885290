import { api } from "config/apiHandler";

const trainings = api.injectEndpoints({
  endpoints: (build) => ({
    getTrainings: build.query({
      query: ({ projectId }) => ({
        url: `/trainings?project_id=${projectId}`,
        method: "GET",
      }),
      providesTags: ["trainings"],
      transformResponse: async (baseQueryReturnValue, meta, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
    }),

    getTrainingById: build.query({
      query: (id) => ({
        url: `/trainings/${id}`,
        method: "GET",
      }),
      providesTags: ["training"],
      transformResponse: async (baseQueryReturnValue, meta, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
    }),

    createTrainings: build.mutation({
      query: (payload) => ({
        url: "/trainings",
        method: "POST",
        body: payload,
      }),
      transformResponse: (baseQueryReturnValue, meta, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => baseQueryReturnValue,
      invalidatesTags: ["trainings"],
    }),

    updateTraining: build.mutation({
      query: (payload) => ({
        url: `/trainings/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
      transformResponse: (baseQueryReturnValue, meta, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => baseQueryReturnValue,
      invalidatesTags: ["trainings", "training"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateTrainingsMutation,
  useGetTrainingsQuery,
  useGetTrainingByIdQuery,
  useUpdateTrainingMutation,
} = trainings;
