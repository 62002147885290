//  components
import Box from "components/Box";
//  context
import { setLayout, useUIController } from "context";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useEffect } from "react";
// react-router-dom components
import { useLocation } from "react-router-dom";

function PageLayout({ background = "default", children }) {
  const [, dispatch] = useUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <Box height="100%" minHeight="100vh" bgColor={background} sx={{ overflowX: "hidden" }}>
      {children}
    </Box>
  );
}

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
