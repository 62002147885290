import SendIcon from "@mui/icons-material/Send";
import { Paper, useTheme } from "@mui/material";
import { Box, Button, Input } from "components";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Result from "../Result";

function TextInference({ handleCreateQuery }) {
  const [inputText, setInputText] = useState("");
  const [result, setResult] = useState(null);
  const theme = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputText.trim()) return;
    try {
      const response = await handleCreateQuery({ text: inputText });
      console.log(response);
      setResult(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          p: { xs: 3, md: 4 },
          borderRadius: 3,
          background: `linear-gradient(145deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
          border: `1px solid ${theme.palette.divider}`,
          backdropFilter: "blur(10px)",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: (_theme) =>
              `linear-gradient(90deg, ${_theme.palette.primary.main}, ${_theme.palette.secondary.main})`,
          },
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 4 }}
        >
          <Input
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            placeholder="Type or paste your text here for analysis..."
            value={inputText}
            onChange={handleInputChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                backgroundColor: theme.palette.background.paper,
                transition: "all 0.3s ease",
                "&:hover, &.Mui-focused": {
                  backgroundColor: theme.palette.background.default,
                  boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
                },
              },
            }}
          />
          <Button
            variant="contained"
            size="large"
            endIcon={<SendIcon />}
            type="submit"
            disabled={!inputText.trim()}
            sx={{
              borderRadius: 2,
              py: 1.5,
              background: (_theme) =>
                `linear-gradient(135deg, ${_theme.palette.primary.main}, ${_theme.palette.secondary.main})`,
              transition: "transform 0.2s ease",
              "&:hover": {
                transform: "translateY(-2px)",
              },
              "&:disabled": {
                background: theme.palette.action.disabledBackground,
              },
            }}
          >
            Send
          </Button>
        </Box>
      </Paper>

      {result && <Result predictions={result.predictions} duration={result.duration} />}
    </>
  );
}

TextInference.propTypes = {
  handleCreateQuery: PropTypes.func.isRequired,
};

export default TextInference;
