import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ReactComponent as LoginCardImage } from "assets/images/loginCardImage.svg";
import linearGradient from "assets/theme/functions/linearGradient";
import { Box } from "components";
import { DefaultNavbar, PageLayout } from "modules";
import PropTypes from "prop-types";
import Footer from "views/Auths/components/Footer";

function BasicLayout({ children, navMenu }) {
  return (
    <PageLayout>
      <DefaultNavbar transparent navMenu={navMenu} />
      <Box
        position="absolute"
        width="100%"
        height="calc(100vh - 56px)"
        mx="auto"
        sx={{
          background: (theme) => {
            const { background } = theme.palette.gradients;
            return linearGradient(background.main, background.state, 221);
          },
          overflowY: "scroll",
        }}
      >
        <Container sx={{ height: "inherit" }}>
          <Grid container height="100%" columns={12}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container justifyContent="center" alignItems="center" height="100%">
                <Grid item xs={11} sm={9} md={6} lg={6} xl={8}>
                  {children}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={({ breakpoints, functions: { pxToRem } }) => ({
                display: "none",
                [breakpoints.up("sm")]: {
                  display: "block",
                },
              })}
            >
              <Grid container justifyContent="center" alignItems="center" height="100%">
                <Grid item xs={12} sm={9} md={6} lg={6} xl={8}>
                  {/* <LoginCardImage /> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Footer light />
      </Box>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  navMenu: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default BasicLayout;
