import { CheckCircleOutline, FmdBadOutlined } from "@mui/icons-material";
import { Divider, Menu, MenuItem } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Typography } from "components";
import PropTypes from "prop-types";
import React from "react";

const notifications = [
  {
    id: 1,
    icon: <CheckCircleOutline fontSize="small" color="success" />,
    text: "Employee Engagement 2023 was launched on 25 Sep 2023 at 11:00 a.m.",
  },
  {
    id: 2,
    icon: <FmdBadOutlined color="warning" fontSize="small" />,
    text: "Employee Engagement 2023 was cancelled on 25 Sep 2023 at 11:00 a.m.",
  },
  {
    id: 3,
    icon: <CheckCircleOutline fontSize="small" color="success" />,
    text: "Employee Engagement 2023 ended on 25 Sep 2023 at 11:00 a.m with 1000 responses.",
  },
];

function Notification({ anchorEl = null, open, handleClose }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={({ palette }) => ({
        "& .MuiMenu-paper": {
          display: "flex",
          flexDirection: "column",
          width: "22%",
          height: "40%",
          mt: pxToRem(30),
          padding: `${pxToRem(8)} ${pxToRem(8)}`,
          overflowY: "scroll",
          border: `1px solid ${palette.grey[300]}`,
          boxShadow: "none",
        },
      })}
    >
      {notifications.map((notification, index) => (
        <Box key={notification.id}>
          <MenuItem
            onClick={handleClose}
            className="flex-display"
            padding={pxToRem(16)}
            key={notification.id}
          >
            <Box
              sx={{
                display: "flex",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                gap: pxToRem(8),
              }}
              key={notification.id}
            >
              {notification.icon}
              <Typography variant="inherit">{notification.text}</Typography>
            </Box>
          </MenuItem>
          {index < notifications.length - 1 && (
            <Divider sx={({ palette }) => ({ backgroundColor: palette.grey[500] })} />
          )}
        </Box>
      ))}
    </Menu>
  );
}

Notification.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default Notification;
