import { Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function index({
  menuList,
  onSelectItem,
  anchorEl = null,
  open,
  handleClose,
  selectedItem = null,
}) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiMenu-paper": {
          width: "inherit",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.08)", // Soft and large shadow for a floating effect
          borderRadius: "5px",
          margin: "5px",
          background: "linear-gradient(145deg, #e0e0e0, #f5f5f5)",
          transform: "scale(0.98)",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1)",
          },
        },
        "& .MuiMenu-list": {
          padding: "10px 0",
        },
      }}
    >
      {menuList.map((item) => (
        <MenuItem
          data-testid={item.code}
          selected={selectedItem && selectedItem.code === item.code}
          key={item.code}
          onClick={() => {
            onSelectItem(item);
          }}
          sx={(theme) => {
            const { typography } = theme;
            const { fontWeightRegularLight, size } = typography;
            return {
              fontSize: size.xs,
              fontWeight: fontWeightRegularLight,
            };
          }}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

index.propTypes = {
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedItem: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  onSelectItem: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // An HTML element, or a function that returns one. It's used to set the position of the menu.
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default index;
