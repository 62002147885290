import { api } from "config/apiHandler";

const query = api.injectEndpoints({
  endpoints: (build) => ({
    createQuery: build.mutation({
      query: (payload) => ({
        url: "/query",
        method: "POST",
        body: payload,
      }),

      transformResponse: (baseQueryReturnValue, meta, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => baseQueryReturnValue,
    }),
  }),
  overrideExisting: false,
});

export const { useCreateQueryMutation } = query;
export default query;
