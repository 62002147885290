import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "assets/theme";
import { Loader } from "components";
import { api } from "config/apiHandler";
import Auths from "containers/Auths";
import Inference from "containers/Inference";
import FAQs from "containers/static/FAQs";
import PrivacyPolicy from "containers/static/PrivacyPolicy";
import { DashboardNavbar } from "modules";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes, { RequireAuth } from "routes/routes";
import cookiesManipulator from "services/browserStorage/cookies";
import { ConfirmationServiceProvider } from "services/customHooks/useConfirmation";
import { useLogoutMutation } from "store/services/authsApi";
import AboutUs from "views/static/AboutUs";

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [logout, { isLoading }] = useLogoutMutation();
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const handleLogout = async () => {
    const payload = { active: 0 };
    try {
      await logout(payload).unwrap();
      dispatch(api.util.resetApiState());
      cookiesManipulator.removeAuth();
      navigate("/auths/login");
    } catch (error) {
      toast.error(error.message);
    }
  };
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes, parentRoute = "") =>
    allRoutes.flatMap((route) => {
      const { route: currentRoute, layout, component, subRoutes } = route;
      const fullRoute = parentRoute + currentRoute;
      const routePath = layout + fullRoute;

      const routeComponent = component && (
        <Route
          exact
          path={routePath}
          element={<RequireAuth>{component}</RequireAuth>}
          key={route.key}
        />
      );

      if (subRoutes && subRoutes.length > 0) {
        const subRoutesComponents = getRoutes(subRoutes, fullRoute);

        return [routeComponent, ...subRoutesComponents];
      }

      if (route.fragmentIdentifier) {
        const fragmentPath = `${routePath}#${route.fragmentIdentifier}`;
        const fragmentComponent = component && (
          <Route
            exact
            path={fragmentPath}
            element={<RequireAuth>{component}</RequireAuth>}
            key={`${route.key}-fragment`}
          />
        );

        return [routeComponent, fragmentComponent];
      }

      return routeComponent;
    });

  const hideSidenavOnTheseRoutes = ["auths"];

  function shouldHideSidebar(currentRoute) {
    if (currentRoute.split("/").includes("project")) return false;
    if (currentRoute.split("/").includes("auths")) return false;
    if (currentRoute.split("/").includes("inference")) return false;
    return true;

    // return !hideSidenavOnTheseRoutes.some((route) => currentRoute.includes(route));
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfirmationServiceProvider>
        {shouldHideSidebar(location.pathname) && cookiesManipulator.getAuth().token && (
          <DashboardNavbar
            handleLogout={handleLogout}
            userData={{ status: "rejected", data: null }}
          />
        )}
        <Loader toggle={isLoading} />
        <Toaster
          position="bottom-right"
          toastOptions={{
            duration: 4000,
            success: {
              position: "bottom-right",
              style: {
                background: theme.palette.background.default,
                color: theme.palette.primary.main,
                fontSize: theme.typography.fontSize,
                fontWeight: theme.typography.fontWeightMedium,
              },
              iconTheme: {
                primary: theme.palette.success.main,
                secondary: theme.palette.background.default,
              },
            },
            error: {
              style: {
                background: theme.palette.background.default,
                color: theme.palette.primary.main,
                fontSize: theme.typography.fontSize,
                fontWeight: theme.typography.fontWeightMedium,
              },
              iconTheme: {
                primary: theme.palette.error.main,
                secondary: theme.palette.background.default,
              },
            },
          }}
        />
        <Routes>
          {getRoutes(routes)}
          <Route
            path="/auths/login"
            element={cookiesManipulator.getAuth().token ? <Navigate to="/projects" /> : <Auths />}
          />
          <Route path="*" element={<Navigate to="/auths/login" />} />
          <Route path="/static/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/static/faq" element={<FAQs />} />
          <Route path="/static/about-us" element={<AboutUs />} />
          <Route path="/inference/:trainingId" element={<Inference />} />
        </Routes>
      </ConfirmationServiceProvider>
    </ThemeProvider>
  );
}
