import Cookies from "universal-cookie";

const cookies = new Cookies();
const expireDate = new Date();
expireDate.setFullYear(expireDate.getFullYear() + 1); // remove this line
expireDate.setDate(expireDate.getDate() + 7);

const options = {
  path: "/",
  secure: true,
  // domain: window.location.hostname,
  expires: expireDate,
};

const cookiesManipulator = {
  setAuth: (data) => {
    const { session, user } = data;
    // options.expires = new Date(session.expiry); // enable this line to set expiry date to the expiry date of the session
    cookies.set("token", session.token, options);
    cookies.set("user", user, options);
  },
  removeAuth: () => {
    cookies.remove("token", options);
    cookies.remove("user", options);
  },
  getAuth: () => ({
    token: cookies.get("token", options),
    user: cookies.get("user", options),
    role: cookies.get("role", options),
  }),
  setCookies: (key, value) => cookies.set(key, value, options),
  getCookies: (key) => cookies.get(key, options),
  removeCookies: (key) => cookies.remove(key, options),

  extendAuthExpiry: () => {
    const token = cookies.get("token");
    const user = cookies.get("user");

    if (token || user) {
      const newExpireDate = new Date();
      newExpireDate.setDate(newExpireDate.getDate() + 7);

      if (token) {
        cookies.set("token", token, { ...options, expires: newExpireDate });
      }

      if (user) {
        cookies.set("user", user, { ...options, expires: newExpireDate });
      }
    }
  },
};

export default cookiesManipulator;
