import TimerIcon from "@mui/icons-material/Timer";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box, Chip, Fade, LinearProgress, Paper, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function Result({ predictions, duration }) {
  const theme = useTheme();

  // Sort predictions by confidence in descending order
  const sortedPredictions = [...predictions].sort((a, b) => b.confidence - a.confidence);

  // Convert confidence to percentage
  const getConfidencePercentage = (confidence) => Math.round(confidence * 100);

  return (
    <Fade in>
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          p: { xs: 3, md: 4 },
          borderRadius: 3,
          background: `linear-gradient(145deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
          border: `1px solid ${theme.palette.divider}`,
          backdropFilter: "blur(10px)",
          position: "relative",
          overflow: "hidden",
          mt: 4,
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: (_theme) =>
              `linear-gradient(90deg, ${_theme.palette.primary.main}, ${_theme.palette.secondary.main})`,
          },
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              background: (_theme) =>
                `linear-gradient(135deg, ${_theme.palette.primary.main}, ${_theme.palette.secondary.main})`,
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              mb: 1,
            }}
          >
            Results
          </Typography>
          <Chip
            icon={<TimerIcon fontSize="small" />}
            label={`Processed in ${duration}ms`}
            size="small"
            sx={{
              backgroundColor: `${theme.palette.primary.main}15`,
              color: theme.palette.primary.main,
              fontWeight: 500,
            }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {sortedPredictions.map((prediction, index) => (
            <Box key={prediction.intent}>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    textTransform: "capitalize",
                    wordBreak: "break-word",
                  }}
                >
                  {index === 0 && (
                    <VerifiedIcon
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 20,
                      }}
                    />
                  )}
                  {prediction.intent.replaceAll("_", " ")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: index === 0 ? theme.palette.primary.main : "text.primary",
                  }}
                >
                  {getConfidencePercentage(prediction.confidence)}%
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={getConfidencePercentage(prediction.confidence)}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: theme.palette.grey[200],
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 4,
                    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  },
                }}
              />
            </Box>
          ))}
        </Box>
      </Paper>
    </Fade>
  );
}

Result.propTypes = {
  predictions: PropTypes.arrayOf(
    PropTypes.shape({
      intent: PropTypes.string.isRequired,
      confidence: PropTypes.number.isRequired,
    })
  ).isRequired,
  duration: PropTypes.number.isRequired,
};

export default Result;
