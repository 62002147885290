import { Loader } from "components";
import React from "react";
import { useParams } from "react-router-dom";
import { useCreateQueryMutation } from "store/services/queryApi";
import { useGetTrainingByIdQuery } from "store/services/trainingsApi";
import InferenceView from "views/Inference";

function Inference() {
  const params = useParams();
  const trainingId = atob(params.trainingId);
  const getTrainingStates = useGetTrainingByIdQuery(trainingId);
  const [createQuery, createQueryStates] = useCreateQueryMutation();

  const handleCreateQuery = async (payload) => {
    try {
      const response = await createQuery({
        ...payload,
        training_id: Number(trainingId),
      }).unwrap();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <InferenceView getTrainingStates={getTrainingStates} handleCreateQuery={handleCreateQuery} />
  );
}

export default Inference;
