import { Card } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { Alert, Box, Button, Input, Loader, Typography } from "components";
import PropTypes from "prop-types";
import { useState } from "react";
import { isEmail } from "services/validations/input";
import BasicLayout from "views/Auths/components/BasicLayout";

const credentialsInitialState = {
  email: "",
  password: "",
};

function Auths({ handleLogin, isLoading, isError, reset, error }) {
  const [credentials, setCredentials] = useState(credentialsInitialState);
  const [showErrors, setShowErrors] = useState(false);

  const handleInputChange = (e) => {
    setShowErrors(false);
    const { value, name } = e.target;

    setCredentials((prevProp) => ({ ...prevProp, [name]: value }));
  };

  const handleSubmit = () => {
    setShowErrors(true);
    if (isEmail(credentials.email) && credentials.password) {
      const payload = {
        ...credentials,
        email: credentials.email.toLowerCase(),
      };
      handleLogin(payload);
    }
  };
  const navMenu = [
    { name: "How this works", route: "/how-this-works" },
    { name: "Pricing", route: "/pricing" },
    { name: "Contact", route: "/contact" },
  ];
  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <>
      {isError ? (
        <Alert alertType="error" toggle={isError} handleCloseError={reset} duration={0}>
          {error?.message}
        </Alert>
      ) : null}
      <BasicLayout navMenu={navMenu}>
        <Card
          className="flex-container-column align-items-start overflow-hidden box-shadow justify-content-between"
          sx={{
            height: pxToRem(390),
            padding: "2rem 2.75rem",
            width: {
              lg: pxToRem(508),
              md: pxToRem(410),
              sm: "unset",
            },
            gap: 2,
          }}
        >
          <Box width="100%" height={pxToRem(28)}>
            <Typography
              variant="h4"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: {
                  fontSize: pxToRem(20),
                },
              })}
            >
              Login
            </Typography>
          </Box>
          {isError ? (
            <Alert alertType="error" toggle={isError} handleCloseError={reset} duration={0}>
              {error?.message}
            </Alert>
          ) : null}
          <Box component="form" role="form" className="width-100">
            <Box mb={2}>
              <>
                <Typography
                  variant="h6"
                  sx={({ breakpoints }) => ({
                    marginBottom: "0rem",
                    marginTop: pxToRem(20),
                    [breakpoints.down("md")]: {
                      fontSize: pxToRem(15),
                    },
                  })}
                >
                  Email
                </Typography>

                <Input
                  name="email"
                  type="email"
                  id="email-input"
                  value={credentials.email}
                  error={showErrors && !isEmail(credentials.email)}
                  success={isEmail(credentials.email)}
                  disabled={isLoading}
                  placeholder="Enter email"
                  helperText={showErrors && !isEmail(credentials.email) ? "Invalid email" : ""}
                  fullWidth
                  onChange={handleInputChange}
                />
                <Typography
                  variant="h6"
                  sx={({ breakpoints }) => ({
                    marginBottom: "0rem",
                    marginTop: pxToRem(20),
                    [breakpoints.down("md")]: {
                      fontSize: pxToRem(15),
                    },
                  })}
                >
                  Password
                </Typography>
                <Input
                  name="password"
                  type="password"
                  id="password-input"
                  value={credentials.password}
                  error={showErrors && credentials.password === ""}
                  success={credentials.password !== ""}
                  disabled={isLoading}
                  placeholder="Enter password"
                  helperText={
                    showErrors && credentials.password === "" ? "Password is required" : ""
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                    handleEnterKeyPress(e);
                  }}
                  fullWidth
                  onChange={handleInputChange}
                />
              </>
              <Box sx={{ marginTop: pxToRem(24) }}>
                <Button
                  variant="contained"
                  color="primary"
                  id="next"
                  onClick={handleSubmit}
                  fullWidth
                  disabled={isLoading}
                  startIcon={isLoading && <Loader type="circular" size={20} color="primary" />}
                >
                  {!isLoading && (
                    <Typography variant="button" color="white">
                      Login
                    </Typography>
                  )}
                </Button>
              </Box>
            </Box>

            <Box textAlign="center">
              <Box>
                <Typography
                  color="black"
                  variant="d7"
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: pxToRem(8),
                    },
                  })}
                >
                  By continuing, you agree to our Terms and Conditions
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </BasicLayout>
    </>
  );
}

export default Auths;

Auths.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  error: PropTypes.any,
};
